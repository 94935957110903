import {useCallback, useEffect, useState} from 'react';
import {
  Appointment,
  RequestPaymentForAppointment,
  SliceStatus,
} from 'interfaces';
import {useSelector} from 'react-redux';
import {AppointmentService} from 'services/api';

import {selectDueAppointments} from '../features/Appointment';
import {getFormattedMemberAppointmentDate} from '../services/Booking/helpers';

import {useQuery} from './useQuery';

export const usePaymentReq = (): {
  meetingData: RequestPaymentForAppointment | undefined;

  isLoading: SliceStatus;
  showPaymentRequestModal: boolean;
  showSuccessPaymentModal: boolean;

  setShowPaymentRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  isPaymentDue: boolean;
} => {
  const dueAppointments = useSelector(selectDueAppointments);
  const [meetingExpired, setMeetingExpired] = useState<boolean>(false);
  const appointmentID = useQuery().get('payRequestId');
  const [meetingData, setMeetingData] =
    useState<RequestPaymentForAppointment>();
  const [showPaymentRequestModal, setShowPaymentRequestModal] =
    useState<boolean>(false);
  const [showSuccessPaymentModal, setShowSuccessPaymentModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<SliceStatus>(SliceStatus.idle);

  const handleMeetingData = useCallback(
    async (id: string) => {
      setLoading(SliceStatus.pending);
      try {
        const response =
          await AppointmentService.fetchDigitalPracticeAppointmentDetails(id);
        const meetingDetails = response.data.message;

        if (!meetingDetails) {
          return;
        }

        setMeetingData(meetingDetails);
        setLoading(SliceStatus.resolved);

        const meetingStartTime = getFormattedMemberAppointmentDate(
          meetingDetails as unknown as Appointment,
        );
        const meetingStarted =
          !meetingExpired && new Date() >= meetingStartTime;

        if (!meetingStarted || meetingExpired) {
          setShowPaymentRequestModal(true);
        }
      } catch (error) {
        setLoading(SliceStatus.rejected);
      }
    },
    [appointmentID, meetingExpired],
  );

  const isPaymentDue =
    dueAppointments &&
    Array.isArray(dueAppointments) &&
    dueAppointments?.length > 0;

  useEffect(() => {
    if (!meetingData || dueAppointments?.length === 0) {
      setMeetingExpired(false);
      return;
    }

    const meetingDuration = Number(meetingData.duration) ?? 0;
    const meetingStartTime = getFormattedMemberAppointmentDate(
      meetingData as unknown as Appointment,
    );
    const meetingEndTime = new Date(
      meetingStartTime.getTime() + Number(meetingDuration) * 60000,
    );
    const remainingTime = meetingEndTime.getTime() - new Date().getTime();
    const isMeetingOver = new Date() > meetingEndTime;
    setMeetingExpired(isMeetingOver);

    if (dueAppointments?.length > 0 && !isMeetingOver) {
      const clearTimeout = setTimeout(() => {
        setMeetingExpired(true);
      }, remainingTime);

      return () => window.clearTimeout(clearTimeout);
    }
    return () => {};
  }, [dueAppointments, meetingData]);

  useEffect(() => {
    if (isPaymentDue) {
      handleMeetingData(dueAppointments[0]);
    } else if (appointmentID) {
      handleMeetingData(appointmentID);
    } else {
      setShowPaymentRequestModal(false);
    }
  }, [
    isPaymentDue,
    appointmentID,
    handleMeetingData,
    dueAppointments,
    meetingExpired,
  ]);

  return {
    meetingData,
    isLoading: loading,
    showPaymentRequestModal,
    showSuccessPaymentModal,
    setShowPaymentRequestModal,
    setShowSuccessPaymentModal,
    isPaymentDue,
  };
};
