import React, {FC, lazy} from 'react';
import {MotionDiv} from 'components/Animation';
import {selectUserProfile} from 'features/User';
import {useMemberOnboarding} from 'hooks/useMemberOnboarding';
import {PaypalStandardPaymentCountries, UserAccountType} from 'interfaces';
import {useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {StripeService} from 'services';
import {
  componentLoader,
  isDigitalPracticeOutOfPocketMember,
  isMember,
  isOutsideWellniteMember,
  isPaypalStandardPaymentCountries,
  isProvider,
} from 'utils';

const UserPhoneVerificationModal = lazy(() =>
  componentLoader(() => import('features/Member/Onboarding/PhoneVerification')),
);

const UserInsuranceModal = lazy(() =>
  componentLoader(() => import('features/Member/Onboarding/Insurance')),
);

//Projected intake feature deployment date in milliseconds/ Tue May 21 2024
const intakeDeploymentDate = 1716303600000;

export const withSubscription: (Component: FC) => () => JSX.Element = (
  Component: FC,
) => {
  const WithSubscriptionWrapper = () => {
    const {displayInsuranceModal, displayPhoneVerificationModal} =
      useMemberOnboarding();
    const user = useSelector(selectUserProfile);
    const location = useLocation();

    if (isMember(user)) {
      const userPlan = user!.__subPaymentPlan || user!.paymentPlan;
      const isPaypalStandardMember = isPaypalStandardPaymentCountries(
        user.countryOfResidence.code as PaypalStandardPaymentCountries,
      );

      if (user.paidOrTrialAccess?.access) {
        //redirect digital practice member to onboarding flow if onboarding  incomplete
        if (
          isOutsideWellniteMember(user) &&
          !user.digitalPracticeAccountType &&
          !user?.stripeCustomerCards?.length &&
          !user?.paypalDefaultCard &&
          !user?.paystackDefaultCard &&
          user.therapist_Id &&
          !user.insuranceDetails?.companyType &&
          !isPaypalStandardMember
        ) {
          return (
            <MotionDiv innerWidth={769}>
              <Redirect to={`/digital-practice/${user.therapist_Id}`} />
            </MotionDiv>
          );
        }

        if (
          !user.whereFrom &&
          !location.pathname.endsWith('/thanks') &&
          user.isPhoneVerified === false
        ) {
          const redirectUrl =
            user.accountType === UserAccountType.scale
              ? '/scale/thanks'
              : `/${userPlan}/thanks`;
          return (
            <MotionDiv innerWidth={769}>
              <Redirect to={redirectUrl} />
            </MotionDiv>
          );
        }

        if (
          user.whereFrom &&
          !(
            Array.isArray(user?.patientCards) && user!.patientCards.length > 0
          ) &&
          !(
            Array.isArray(user?.paystackCards) && user.paystackCards.length > 0
          ) &&
          user?.date &&
          user.date > intakeDeploymentDate &&
          !isDigitalPracticeOutOfPocketMember(user) &&
          !isPaypalStandardMember
        ) {
          return <Redirect to="/add-credit-card" />;
        }

        if (displayPhoneVerificationModal && user?.whereFrom) {
          return <UserPhoneVerificationModal />;
        }

        if (displayInsuranceModal && user?.whereFrom) {
          return <UserInsuranceModal />;
        }

        return <Component />;
      }
      const paymentOrUpdatePlanPage =
        StripeService.getPaymentOrUpdatePlanRoute(user);

      return (
        <MotionDiv innerWidth={769}>
          <Redirect to={paymentOrUpdatePlanPage} />
        </MotionDiv>
      );
    }
    if (isProvider(user)) {
      return <Component />;
    }
    return (
      <MotionDiv innerWidth={769}>
        <Redirect to="/login" />
      </MotionDiv>
    );
  };
  return WithSubscriptionWrapper;
};
