import {ProviderRole, UserRoles} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const checkUserExists = async (data: {
  email: string;
}): Promise<Response<{message: {exists: boolean}}>> => {
  try {
    const res = await requestHandler<{
      message: {exists: boolean};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.CheckUserExists}/?email=${data.email}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const handleCountryMismatch = async ({
  role,
  ...rest
}: {
  patientEmail: string;
  providerEmail: string;
  providerRole: ProviderRole;
  actionAttempted: 'booking' | 'video call';
  role: UserRoles;
}): Promise<Response<{message: {exists: boolean}}>> => {
  try {
    const res = await requestHandler<{
      message: {exists: boolean};
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}/${EndPoints.HandleCountryMismatch}` as unknown as EndPoints,
      data: rest,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
export const UserService = {checkUserExists, handleCountryMismatch};
