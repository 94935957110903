import {
  GenerateMagicContentArgs,
  GenerateMagicContentResponse,
  MagicContentArticle,
  MagicContentDraftState,
  RegenerateMagicContentType,
  UnsplashSearchResults,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const recoverMagicContentDraft = createAction<MagicContentDraftState>(
  'recoverMagicContentDraft',
);

export const generateExpertSummary = createAction<{
  onSuccess: (result: string) => void;
}>('generateExpertSummary');
export const generateExpertSummarySuccess = createAction<string>(
  'GenerateExpertSummarySuccess',
);
export const generateExpertSummaryFailure = createAction<string>(
  'GenerateExpertSummaryFailure',
);

export const generateMagicContent = createAction<
  GenerateMagicContentArgs & {
    onSuccess?: () => void;
  }
>('generateMagicContent');
export const generateMagicContentSuccess =
  createAction<GenerateMagicContentResponse>('GenerateMagicContentSuccess');
export const generateMagicContentFailure = createAction<string>(
  'GenerateMagicContentFailure',
);

export const reGenerateMagicContent = createAction<
  GenerateMagicContentArgs & {
    element: RegenerateMagicContentType;
    onSuccess?: (payload: Partial<GenerateMagicContentResponse>) => void;
  }
>('reGenerateMagicContent');
export const reGenerateMagicContentSuccess = createAction<
  Partial<GenerateMagicContentResponse> & {
    element: RegenerateMagicContentType;
  }
>('ReGenerateMagicContentSuccess');
export const reGenerateMagicContentFailure = createAction<{
  error: string;
  element: RegenerateMagicContentType;
}>('ReGenerateMagicContentFailure');

export const setMagicArticle =
  createAction<MagicContentArticle>('SetMagicArticle');

export const resetMagicContent = createAction('ResetMagicContent');

export const saveMagicArticle = createAction<
  MagicContentArticle & {
    onSuccess?: (payload: MagicContentArticle) => void;
  }
>('saveMagicArticle');
export const saveMagicArticleSuccess = createAction<MagicContentArticle>(
  'SaveMagicArticleSuccess',
);
export const saveMagicArticleFailure = createAction<string>(
  'SaveMagicArticleFailure',
);

export const getMagicImages = createAction<{
  count: number;
  query: string;
  page?: number;
}>('getMagicImages');
export const getMagicImagesSuccess = createAction<UnsplashSearchResults>(
  'GetMagicImagesSuccess',
);
export const getMagicImagesFailure = createAction<string>(
  'GetMagicImagesFailure',
);

export const getMagicArticleBySlug = createAction<{
  slug: string;
}>('getMagicArticleBySlug');
export const getMagicArticleBySlugSuccess = createAction<MagicContentArticle>(
  'GetMagicArticleBySlugSuccess',
);
export const getMagicArticleBySlugFailure = createAction<string>(
  'GetMagicArticleBySlugFailure',
);

export const getMagicArticlesByProvider = createAction<{
  providerId: string;
}>('getMagicArticlesByProvider');
export const getMagicArticlesByProviderSuccess = createAction<
  MagicContentArticle[]
>('GetMagicArticlesByProviderSuccess');
export const getMagicArticlesByProviderFailure = createAction<string>(
  'GetMagicArticlesByProviderFailure',
);

export const checkIfProviderReachedDailyMagicContentLimit = createAction(
  'CheckIfProviderReachedDailyMagicContentLimit',
);
export const checkIfProviderReachedDailyMagicContentLimitSuccess =
  createAction<boolean>('CheckIfProviderReachedDailyMagicContentLimitSuccess');
export const checkIfProviderReachedDailyMagicContentLimitFailure =
  createAction<string>('CheckIfProviderReachedDailyMagicContentLimitFailure');

export const triggerDownloadUnsplashPhotoEvent = createAction<{url: string}>(
  'TriggerDownloadUnsplashPhotoEvent',
);
export const triggerDownloadUnsplashPhotoEventSuccess = createAction(
  'TriggerDownloadUnsplashPhotoEventSuccess',
);
export const triggerDownloadUnsplashPhotoEventFailure = createAction<string>(
  'TriggerDownloadUnsplashPhotoEventFailure',
);
