import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AppDispatch} from 'app/store';
import {useNow} from 'components/Basic/BigCalendar/hooks/useNow';
import {addMinutes, format, isAfter, isBefore, isSameDay} from 'date-fns';
import dayjs from 'dayjs';
import {
  appointmentActions,
  selectCalendarAppointments,
  selectCalendarLoadingState,
} from 'features/Appointment';
import {CalendarItem} from 'features/Appointment/Calendar/CalendarItem';
import {selectUserProfile} from 'features/User';
import {
  Appointment,
  AppointmentLabels,
  CountryOfResidenceType,
  MemberProfile,
  ProviderRole,
  SliceStatus,
  UserRoles,
  WherebyRoomModeTypes,
} from 'interfaces';
import {Meeting} from 'interfaces/Meeting.types';
import {DayModifiers, Modifier} from 'react-day-picker';
import {useDispatch, useSelector} from 'react-redux';
import {getFormattedMemberAppointmentDate} from 'services/Booking/helpers';
import {dateTimeStringCompatibility, isMember, isProvider} from 'utils';

import {useCalendarLocalization} from './useCalendarLocalization';

import 'react-day-picker/lib/style.css';

const now = new Date(new Date().setHours(0, 0, 0, 0));
const formattedNow = format(now, 'yyyy-MM-dd');

export type FormattedAppointmentType = {
  startTime: string;
  endTime: string;
  duration: string;
  appointmentID: number;
  classID?: number;
  notes?: string;
  labels: {id: number; name: string; color: string}[] | null;
  calendar: string;
  providerId?: string;
  category: string;
  timeZone: string;
  patientFirstName?: string;
  patientLastName?: string;
  patientDetails?: MemberProfile;
  meeting?: Meeting;
  localTime: Date;
  roomMode?: WherebyRoomModeTypes;
  canceled?: boolean;
  cancelAppointment: (appointmentID: number) => void;
  providerDetails?: {
    email: string;
    countryOfResidence: CountryOfResidenceType;
    role: ProviderRole;
  };
} & {
  participants?: {
    firstName: string;
    lastName: string;
    appointmentID: number;
    labels: AppointmentLabels[] | null;
  }[];
};

export const useCalendar = (
  patientId?: string,
): {
  selectedDay: Date | undefined;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date | undefined>>;
  onDayClick: (day: Date, {selected}: DayModifiers) => void;
  calendarAppointments: Appointment[];
  calendarLoadingState: SliceStatus;
  idToCancel: string;
  now: Date;
  modifiers: {
    appointments: ((day: Date) => boolean)[];
    disabled: Modifier | Modifier[];
  };
  isCalendarOpen: boolean;
  showCalendar: () => void;
  closeCalendar: () => void;
  appointmentCount: (date?: Date) => string;
  appointmentDetails: (
    appt?: FormattedAppointmentType,
    version?: 'v1' | 'v2' | 'v3',
  ) => React.ReactNode;
  fetchAppointments: (date?: Date) => void;
  resetAppointmentIdToCancel: () => void;
  cancelAppointment: (
    reasonForCancellation?: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) => void;
  handleSelectedDay: (day: Date) => void;
  patientAppointmentDetails?: {[key: string]: any};
  localTime?: Date;
  formattedAppts: {
    [key: string]: FormattedAppointmentType[];
  };
  formatAppointment: (appointment: Appointment) => FormattedAppointmentType;
} => {
  const {locale} = useCalendarLocalization();

  const translations = () => {
    switch (locale) {
      case 'es':
        return {
          appointments: 'Citas',
          appointment: 'Cita',
        };
      case 'pt':
        return {
          appointments: 'Consultas',
          appointment: 'consulta',
        };
      case 'en':
      default:
        return {
          appointments: 'Appointments',
          appointment: 'Appointment',
        };
    }
  };
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(new Date());
  const [idToCancel, setIdToCancel] = useState('');
  const [localTime, setlocalTime] = useState<Date>();
  const user = useSelector(selectUserProfile);
  const {now: tNow} = useNow(true);
  const isPastHour = (date: dayjs.Dayjs | null | undefined): boolean =>
    date?.isBefore(tNow, 'hour') || false;

  const setAppointmentIdToCancel = (appointmentId: number) => {
    setIdToCancel(`${appointmentId}`);
  };
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const resetAppointmentIdToCancel = () => {
    setIdToCancel('');
  };
  const closeCalendar = () => {
    setIsCalendarOpen(false);
  };
  const showCalendar = () => {
    setIsCalendarOpen(true);
  };
  const onDayClick = (day: Date, {selected}: DayModifiers) => {
    setSelectedDay(selected ? undefined : day);
  };
  const dispatch = useDispatch<AppDispatch>();
  const calendarAppointments = useSelector(selectCalendarAppointments);
  const calendarLoadingState = useSelector(selectCalendarLoadingState);
  const fetchAppointments = useCallback(
    (date?: Date): void => {
      if (isMember(user)) {
        dispatch(appointmentActions.getCalendarAppointments());
      }
      if (/therapist|prescriber/.test(user?.role ?? '')) {
        dispatch(
          appointmentActions.getProviderCalendarAppointments({
            yearAndMonth: format(date || now, 'yyyy-MM'),
            userRole: user?.role as 'therapist' | 'prescriber',
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch],
  );

  const cancelAppointment = useCallback(
    (
      reasonForCancellation?: string,
      onSuccess?: () => void,
      onError?: () => void,
    ) => {
      console.log('cancel func invoke');

      const cb = () => {
        resetAppointmentIdToCancel();
        if (typeof onSuccess === 'function') onSuccess();
      };
      dispatch(
        appointmentActions.cancelAppointment({
          idToCancel: +idToCancel,
          reasonForCancellation: reasonForCancellation,
          callback: cb,
          onError,
        }),
      );
    },

    [dispatch, idToCancel],
  );

  useEffect(() => {
    if (
      user?.email &&
      calendarLoadingState === SliceStatus.idle &&
      calendarAppointments.length <= 1
    )
      fetchAppointments();
  }, [fetchAppointments, calendarLoadingState, user]);

  const patientAppointmentDetails = useMemo(
    () =>
      calendarAppointments.find(
        (appt: Appointment) => appt.patientId === patientId,
      ),
    [patientId, calendarAppointments],
  );

  const formatAppointment = (curr: Appointment): FormattedAppointmentType => {
    const localTime = getFormattedMemberAppointmentDate(curr);
    const startTime = format(localTime, 'h:mm a');
    const {
      appointmentID,
      classID,
      notes,
      participants,
      labels,
      calendar,
      duration,
      category,
      timeZone,
      providerId,
      firstName: patientFirstName,
      lastName: patientLastName,
      patientId,
      patientDetails,
      meeting,
      roomMode,
      canceled,
      providerDetails,
    } = curr;

    const endTime = format(
      addMinutes(localTime, parseInt(duration, 10)),
      'H:mm',
    );

    const commonProperties = {
      startTime,
      endTime,
      duration,
      appointmentID,
      calendar,
      category,
      providerId,
      timeZone,
      localTime,
      providerDetails,
    };

    const providerProperties = isProvider(user)
      ? {
          patientFirstName,
          patientLastName,
          patientId,
          patientDetails,
          meeting,
          canceled,
        }
      : {};

    const memberProperties = isMember(user)
      ? {
          canceled,
        }
      : {};

    return {
      classID,
      labels,
      notes,
      participants,
      roomMode,
      cancelAppointment: (appointmentID: number) => {
        setAppointmentIdToCancel(appointmentID);
        setlocalTime(localTime);
      },
      ...providerProperties,
      ...commonProperties,
      ...memberProperties,
    };
  };

  const formattedAppts = calendarAppointments.reduce<{
    [key: string]: FormattedAppointmentType[];
  }>((acc, curr) => {
    const localTime = getFormattedMemberAppointmentDate(curr);
    const formattedTime = format(localTime, 'yyyy-MM-dd');

    if (!acc[formattedTime]) {
      acc[formattedTime] = [];
    }

    acc[formattedTime].push(formatAppointment(curr));

    return acc;
  }, {});

  function appointmentDays(day: Date) {
    return Object.keys(formattedAppts).some(apptDay => {
      const formattedDay = new Date(dateTimeStringCompatibility(apptDay));
      return isAfter(formattedDay, now) && isSameDay(formattedDay, day);
    });
  }

  function isAppointmentValid(day: Date) {
    let targetDay = new Date(day);
    const currentDay = new Date();

    targetDay.setMinutes(targetDay.getMinutes() + 90);
    targetDay = new Date(targetDay);

    const _target = new Date(
      targetDay.getFullYear(),
      targetDay.getMonth() + 1,
      targetDay.getDate(),
    );
    const _current = new Date(
      currentDay.getFullYear(),
      currentDay.getMonth() + 1,
      currentDay.getDate(),
    );

    if (isSameDay(_target, _current)) {
      if (targetDay.getHours() === currentDay.getHours()) {
        return targetDay.getMinutes() > currentDay.getMinutes();
      } else return targetDay.getHours() >= currentDay.getHours();
    } else if (isBefore(_target, _current)) return false;
    return true;
  }

  const modifiers = {
    appointments: [appointmentDays],
    disabled: {before: now},
  };

  const appointmentCount = useCallback(
    (date?: Date) => {
      const selectedDate = date ? format(date, 'yyyy-MM-dd') : formattedNow;
      if (!formattedAppts[selectedDate])
        return `0 ${translations().appointments.toLowerCase()}`;
      return formattedAppts[selectedDate].length > 1
        ? `${
            formattedAppts[selectedDate].length
          } ${translations().appointments.toLowerCase()}`
        : `1 ${translations().appointment.toLowerCase()}`;
    },
    [formattedAppts],
  );

  const appointmentDetails = useCallback(
    (appt?: FormattedAppointmentType, version?: 'v1' | 'v2' | 'v3') => {
      if (appt) {
        return (
          <CalendarItem
            {...appt}
            isPastEvent={() => isPastHour(dayjs(appt.localTime))}
            user={user}
            version={version || 'v2'}
          />
        );
      }

      if (!selectedDay) {
        return null;
      }

      const formattedDay = format(selectedDay, 'yyyy-MM-dd');
      if (!formattedAppts[formattedDay]) {
        return null;
      }

      return user?.role === UserRoles.member
        ? formattedAppts[formattedDay]
            .filter(obj => isAppointmentValid(obj.localTime))
            .map((appt, index) => {
              return (
                <CalendarItem
                  key={`${appt.appointmentID}${appt.startTime}${+index}`}
                  {...appt}
                  user={user}
                  cancelAppointment={(appointmentID: number) => {
                    setAppointmentIdToCancel(appointmentID);
                  }}
                  version={version || 'v1'}
                />
              );
            })
            .reverse()
        : formattedAppts[formattedDay]
            .map((appt, index) => (
              <CalendarItem
                key={`${appt.appointmentID}${appt.startTime}${+index}`}
                {...appt}
                user={user}
                version={version || 'v1'}
              />
            ))
            .reverse();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [formattedAppts, selectedDay],
  );

  const handleSelectedDay = (day: Date) => {
    setSelectedDay(day);
  };
  return {
    selectedDay,
    setSelectedDay,
    onDayClick,
    calendarAppointments,
    calendarLoadingState,
    now,
    modifiers,
    appointmentCount,
    appointmentDetails,
    fetchAppointments,
    idToCancel,
    resetAppointmentIdToCancel,
    cancelAppointment,
    isCalendarOpen,
    showCalendar,
    closeCalendar,
    handleSelectedDay,
    patientAppointmentDetails,
    localTime,
    formattedAppts,
    formatAppointment,
  };
};
