import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AuthService,
  MessagingService,
  ReleaseNoteService,
  UserService,
} from 'services/api';

import {loginUserEpics} from './Login/loginEpics';
import {outsideWellniteRegisterUserEpics} from './Register/OutsideWellniteRegister/outsideWellniteRegisterEpics';
import {registerUserEpics} from './Register/registerEpics';
import {resetPasswordEpics} from './ResetPassword/resetPasswordEpics';
import {
  deactivateChatFeature,
  deactivateChatFeatureFailure,
  deactivateChatFeatureSuccess,
  reactivateChatFeature,
  reactivateChatFeatureFailure,
  reactivateChatFeatureSuccess,
} from './userActions';
import {userActions} from './userSlice';

const currentUrl = window?.location?.href || '';
const pathname = window?.location?.pathname;

export const logoutEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(userActions.logout.match),
    withLatestFrom(state$),
    switchMap(([, currState]) =>
      from(
        AuthService.logoutOtherRole(
          currState.user.current?.role ?? UserRoles.member,
        ),
      ).pipe(
        mergeMap(() => [
          userActions.logoutSuccess(),
          userActions.resetUser(null),
          push({
            pathname: currentUrl.includes('/practice/register')
              ? pathname
              : '/login',
          }),
        ]),
        catchError((message: string) =>
          concat(of(userActions.logoutFailure(message))),
        ),
      ),
    ),
  );

const reactivateCometUserEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(reactivateChatFeature.match),
    mergeMap(() =>
      from(MessagingService.reactivateCometUser()).pipe(
        mergeMap(({data}) => [reactivateChatFeatureSuccess(data.message)]),
        catchError((message: string) =>
          concat(of(reactivateChatFeatureFailure(message))),
        ),
      ),
    ),
  );

const deactivateCometUserEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(deactivateChatFeature.match),
    mergeMap(() =>
      from(MessagingService.deactivateCometUser()).pipe(
        mergeMap(({data}) => [deactivateChatFeatureSuccess(data.message)]),
        catchError((message: string) =>
          concat(of(deactivateChatFeatureFailure(message))),
        ),
      ),
    ),
  );

const logoutFailureEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(userActions.logoutFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getReleaseNotesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.getReleaseNotes.match),
    switchMap(() =>
      from(ReleaseNoteService.GetAccountReleaseNotes()).pipe(
        mergeMap(({data}) => [
          userActions.getReleaseNotesSuccess(data.message.result),
        ]),
        catchError(message =>
          concat(of(userActions.getReleaseNotesFailure(message))),
        ),
      ),
    ),
  );

export const updateReleaseNotesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateReleaseNotes.match),
    switchMap(({payload}) =>
      from(ReleaseNoteService.UpdateAccountReleaseNotes(payload)).pipe(
        mergeMap(({data}) => [
          userActions.updateReleaseNotesSuccess(data.message),
        ]),
        catchError(message =>
          concat(of(userActions.updateReleaseNotesFailure(message))),
        ),
      ),
    ),
  );

export const handleCountryMismatchEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.handleCountryMismatch.match),
    mergeMap(({payload: {cb, ...rest}}) =>
      from(UserService.handleCountryMismatch(rest)).pipe(
        map(() => {
          cb?.();
          return userActions.handleCountryMismatchDetected();
        }),
        catchError(message => {
          cb?.();
          return of(userActions.handleCountryMismatchDetected(message));
        }),
      ),
    ),
  );

export const handleCountryMismatchDetectedEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.handleCountryMismatchDetected.match),
    tap(({payload}) => {
      Toast({
        type: 'error',
        message: payload || 'Country of residence mismatch',
      });
    }),
    ignoreElements(),
  );

export const userEpics = [
  loginUserEpics,
  registerUserEpics,
  outsideWellniteRegisterUserEpics,
  resetPasswordEpics,
  [
    logoutEpic,
    logoutFailureEpic,
    reactivateCometUserEpic,
    deactivateCometUserEpic,
    getReleaseNotesEpic,
    updateReleaseNotesEpic,
    handleCountryMismatchEpic,
    handleCountryMismatchDetectedEpic,
  ],
].flatMap(epic => epic);
