import React, {FC, useState} from 'react';
import {MemberBookingModal} from 'features/Appointment/Booking/MemberBookingModal';
import {selectUserProfile} from 'features/User';
import {AppointmentTypes} from 'interfaces';
import {useSelector} from 'react-redux';
import {isMember} from 'utils';

import MemberBookingOptions from './MemberBookingOptions';

/**
 *
 * @description this component handles the appointment booking for Member/Patient
 */
const MemberBookingBtn: FC<{
  type?: 'default' | 'custom';
  cb?: (
    onSetSessionType: (sessionType: AppointmentTypes) => void,
  ) => JSX.Element;
  borderColor?: string;
}> = ({type = 'default', cb, borderColor}) => {
  const user = useSelector(selectUserProfile);
  const [appointmentType, setAppointmentType] = useState<
    AppointmentTypes | undefined
  >(undefined);
  const [showBookingModal, setShowBookingModal] = useState(false);

  const onSetSessionType = (sessionType: AppointmentTypes) => {
    setAppointmentType(sessionType);
    setShowBookingModal(true);
  };
  return (
    <>
      {type === 'default' ? (
        <MemberBookingOptions
          btnClasses="mr-0 lg:mr-6"
          onSetSessionType={onSetSessionType}
          borderColor={borderColor}
        />
      ) : type === 'custom' && cb ? (
        cb(onSetSessionType)
      ) : null}
      {/* Appointment Booking Modal */}
      {isMember(user) && (
        <MemberBookingModal
          show={showBookingModal}
          appointmentType={appointmentType ?? ('' as AppointmentTypes)}
          member={user}
          setShowBookingModal={show => {
            setShowBookingModal(show);
          }}
          onClose={() => {
            setShowBookingModal(false);
            setAppointmentType(undefined);
          }}
        />
      )}
    </>
  );
};

export default MemberBookingBtn;
