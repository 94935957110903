import {
  asyncRequestMagicContentState,
  asyncRequestUserState,
} from './dexie-queries';
import {middleware} from './middleware';
import {outerReducer} from './outerReducer';

export const IndexedDBService = {
  Middleware: middleware,
  OuterReducer: outerReducer,
  GetUserState: asyncRequestUserState,
  GetMagicContentState: asyncRequestMagicContentState,
};
