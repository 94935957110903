import {CountryOfResidenceType} from './Countries.types';
import {MemberProfile, ProviderRole} from './CurrentUser.types';
import {Meeting} from './Meeting.types';

export type Appointment = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfAppointment: string;
  timeOfAppointment: string;
  appointmentID: number;
  classID?: number; // for group appointment
  notes?: string; // for group appointment
  participants?: {
    firstName: string;
    lastName: string;
    appointmentID: number;
    labels: AppointmentLabels[] | null;
    patientId: string;
  }[];
  calendar: string;
  timeZone: string;
  duration: string;
  category: string;
  patientId?: string;
  providerId?: string;
  patientDetails?: MemberProfile;
  meeting?: Meeting;
  roomMode?: WherebyRoomModeTypes;
  labels: AppointmentLabels[] | null;
  canceled?: boolean;
  providerDetails?: {
    email: string;
    countryOfResidence: CountryOfResidenceType;
    role: ProviderRole;
  };
};

export type WherebyRoomModeTypes = 'normal' | 'group';

export type AppointmentLabels = {
  id: number;
  name: string;
  color: string;
};

export type GroupCallAppointmentLabelReqData = {
  appointmentID: number;
  providerID: string;
  labelID?: number;
};

export enum GroupCallAppointmentLabelsIDs {
  interested = 12690056,
  notInterested = 12690075,
  joined = 12690076,
}

export enum WherebyRoomMode {
  group = 'group',
  normal = 'normal',
}

export type RequestPaymentForAppointment = Meeting & {
  timeZone: string;
  duration: string;
  dateOfAppointment: string;
  timeOfAppointment: string;
};
