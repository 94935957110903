import React, {FC} from 'react';
import {useAppSelector} from 'app/store';
import {selectUserProfile} from 'features/User';
import {ProviderCancelAppointmentFormData, ProviderProfile} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isUserFromUnitedStates} from 'utils';

type Props = {
  onSubmit: (formdata: ProviderCancelAppointmentFormData) => void;
  store: {
    reasonForCancellation: string;
    otherReasonForCancellation: string;
  };
  formId: string;
};
const CancellationNotice: FC<Props> = ({formId, store, onSubmit}) => {
  const {t} = useTranslation();
  const {handleSubmit} = useForm({
    defaultValues: store,
  });

  const user = useAppSelector(selectUserProfile) as ProviderProfile;
  const isInternationalProvider = !isUserFromUnitedStates(user);
  const cancellationMessage = isInternationalProvider
    ? t(
        'cancel_appointment.international_provider.noticeMessage',
        `You’re about to cancel a session with less than 24 hours' notice. Please make sure your client is informed. Thank you!`,
      )
    : t(
        'cancel_appointment.us_provider.noticeMessage',
        `Prior to proceeding with the cancellation of your appointment, please be aware that our platform policy stipulates the provision of an additional complimentary session to the patient in situations where less than 24 hours' notice is given. This is in addition to the rescheduled session resulting from the late cancellation. Are you certain you wish to proceed with the late cancellation?`,
      );

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <p className="text-gray-700 py-3 leading-relaxed text-base">
        {cancellationMessage}
      </p>
    </form>
  );
};
export default CancellationNotice;
